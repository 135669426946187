import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const TwoPocketNoteCounter = ({ data }) => {
  return (
    <Layout>
      <SEO title="MIB-11 Two Pocket Mixed Note Value Counter And Sorter" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              MIB-5 Two-Pocket Note Counter
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              Only pay for the functionality you need
            </h3>
            <p className="mb-4">
              The MIB5 is ideal for pubs, clubs and cashier rooms and is
              excellent for ATM and bank note preparation. This unit also has
              infrared counterfeit detection, perfect for identifying forged
              notes and is fully configured for all the new Australian
              banknotes.
            </p>
            <p className="mb-4">
              The MIB-5 two-pocket note counter will count and sort mixed
              denominations while simultaneously scanning for counterfeit notes
              and off-sorting TITO tickets if required. This unit comes with 10
              currencies as standard. Built on the SB5+ technology this unit is
              faster, quieter and has enhanced functionality to process cash at
              hi-speed.
            </p>
            <p className="mb-4">
              The MIB5 will also batch, sort, face, orientate and separate the
              old banknotes against the new ones. Its smooth, quiet and
              user-friendly operation and robust build quality ensures that it
              is one of the most reliable banknote counters available in
              Australia and is widely sought after by Banking and Gaming
              institutions worldwide.
            </p>

            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Specifications</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <td>Stacker Size</td>
                        <td>Up to 200 notes</td>
                      </tr>
                      <td>Reject Pocket</td>
                      <td>Up to 100 notes</td>
                      <tr>
                        <td>Hopper Capacity</td>
                        <td>500 notes with ability to load while running</td>
                      </tr>
                      <tr>
                        <td>Stacker Capacity</td>
                        <td>is now 300 notes</td>
                      </tr>
                      <tr>
                        <td>Counting speed</td>
                        <td>
                          1200 (value count) and 1500 (piece count) per minute
                        </td>
                      </tr>
                      <tr>
                        <td>Dimensions</td>
                        <td>(W) 280mm x (L) 261mm x (H) 271mm</td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>9 kg</td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:mt-0 md:w-1/3">
          <div className="w-full max-w-sm px-4 mx-auto">
            <Image fluid={data.TwoPocketNoteCounter.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

TwoPocketNoteCounter.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query TwoPocketNoteCounterQuery {
    TwoPocketNoteCounter: file(relativePath: { eq: "products/mib5/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default TwoPocketNoteCounter;
